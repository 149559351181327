<template>
<div>
    <van-nav-bar title="视频作业" left-text="返回"  right-text=""  left-arrow  @click-left="onClickLeft" @click-right="onClickRight"  />
      <section class="van-doc-demo-section">
          <div class="van-coupon van-coupon--disabled" v-for="(item,index) in listData" :key="index">
              <div class="van-coupon__content">
                  <div class="van-coupon__head">
                      <h2 class="van-coupon__amount">{{item.Title}}</h2>
                      <p class="van-coupon__condition">发起时间：{{item.LaunchTime}}</p>
                  </div>
                  <div class="van-coupon__body" v-if="item.IsEvaluate"><p class="messg_success">已批改</p></div>
                  <div class="van-coupon__body" v-else-if="item.IsSubmit"><p class="messg_success">已提交</p></div>
                  <div class="van-coupon__body" v-else><p class="messg_warning">未提交</p></div>
              </div>
              <div class="van-coupon__description" v-if="item.IsEvaluate">
                  <button class="van-button  van-button-first" @click="AllCorrect(item)"><div class="van-button_content"><span class="van-button__text">作业详情</span></div></button>
                  <button class="van-button  van-button-last" @click="WorkList(item)"><div class="van-button_content"><span class="van-button__text">评价结果</span></div></button>
              </div>
              <div class="van-coupon__description" v-else>
                  <button class="van-button  van-button-first" @click="AllCorrect(item)"><div class="van-button_content"><span class="van-button__text">作业详情</span></div></button>
                  <button class="van-button  van-button-last"><div class="van-button_content_unactive"><span class="van-button__text">评价结果</span></div></button>
              </div>

          </div>
         <!--<div class="van-coupon van-coupon--disabled">
            <div class="van-coupon__content">
                <div class="van-coupon__head">
                    <h2 class="van-coupon__amount">第三周视频作业</h2>
                    <p class="van-coupon__condition">发起时间：2017/03/10 12:00</p>
                </div>
                <div class="van-coupon__body"><p class="messg_success">已提交</p></div>
            </div>
            <div class="van-coupon__description">
                <button class="van-button  van-button-first" @click="AllCorrect(1)"><div class="van-button_content"><span class="van-button__text">作业详情</span></div></button>
                <button class="van-button  van-button-last" @click="WorkList(2)"><div class="van-button_content"><span class="van-button__text">评价结果</span></div></button>
            </div>
        </div>
        <div class="van-coupon van-coupon--disabled">
            <div class="van-coupon__content">
                <div class="van-coupon__head">
                    <h2 class="van-coupon__amount">第三周视频作业</h2>
                    <p class="van-coupon__condition">发起时间：2017/03/10 12:00</p>
                </div>
                <div class="van-coupon__body"><p class="messg_success">已批改</p></div>
            </div>
            <div class="van-coupon__description">
                <button class="van-button  van-button-first" @click="AllCorrect(1)"><div class="van-button_content"><span class="van-button__text">作业详情</span></div></button>
                <button class="van-button  van-button-last" @click="WorkList(2)"><div class="van-button_content"><span class="van-button__text">评价结果</span></div></button>
            </div>
        </div>-->
    </section>
   
</div>

</template>

<script>
    import Cookies from 'js-cookie';
    export default {
        data() {
            return {
                StudentGroupID: "",//分组ID
                StudentID: "", // 学生ID
                listData: [],
                contentshow: false
            }
        },
        methods: {
            // 获取数据
            initData() {
                this.$axios.get('/api/JobTask/StudentJobTaskList?studentGroupID=' + this.StudentGroupID + "&studentID=" + this.StudentID, "").then(res => {
                    var data = JSON.parse(res.data);
                    console.log(data)
                    if (data.code == 200) {
                        this.listData = data.data;
                    }
                });
            },
            // 点击左上角返回
            onClickLeft() {
                this.$router.push({ name: "home" });
            },
            onClickRight() { },//这个方法不要删，上面导航返回控件必须带的
            AllCorrect(item) {
                 console.log(item);
                this.$router.push({ name: "work2", query: { jobTaskID: item.ID } });
            },
            WorkList(item) {
                 this.$router.push({ name: "workLog", query: { jobTaskID: item.ID } });
            },
            // 点击关闭视频
            closeVideo() {
                this.videoType = false;
            },
            // 点击开启视频引导
            openVideo() {
                this.videoType = true;
            },
            // 点击关闭引导
            closeGuide() {
                this.guideType = !this.guideType;
            }
        },
        mounted() {
            this.StudentID = Cookies.get('studentID');
            this.StudentGroupID = this.cookies.kkGet("StudentGroupID");
            this.initData();
        },
    }
</script>

<style>
.van-doc-demo-section {
    
    box-sizing: border-box;
    min-height: calc(100vh - 56px);
    padding-bottom: 20px;
}
.van-coupon {
    margin: 12px 12px 6px 12px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 4px rgb(222, 219, 219);
}
.van-coupon__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 84px;
    padding: 14px 0;
    color: #323233;
}
.van-coupon--disabled .van-coupon__head {
    color: inherit;
}
.van-coupon__head {
    position: relative;
    width: 280px;
    padding: 0 8px 0 15px;
    color: #ee0a24;
}
.van-coupon__body{
    float:right
}
.messg_warning{color:rgba(237, 122, 112, 1);}
.messg_success{color:rgba(42, 204, 155, 1);}
.van-coupon__amount {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.van-coupon__description {
    font-size: 16px;
    text-align: center;
    border-top: 1px dashed #ebedf0;
}.van-coupon__condition {
    font-size: 12px;
    line-height: 18px;
    white-space: pre-wrap;
}

.van-button_content{
    color: rgb(50, 148, 255);
}

.van-button_content_unactive{
    color: rgb(124, 124, 124);
}

.van-button {
    width:50%;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 44px;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    background: #fff;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    -webkit-appearance: none;
}
.van-button-first {
    margin: 0;
    border: 0;
}
.van-button-last{
    margin: 0;
    border: 0;
    border-left: 2px solid rgb(210, 210, 210);
}

.van-row {
    padding: 5px 0 5px 0;
}
.van-col--8{
  font-weight: 100;
}

</style>